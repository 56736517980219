
.member {
  height: 50%;
}

.input-search-member {
  padding: 0px 16px 16px 16px;
}

.input-search-member-prefix {
  padding: 0px 16px 16px 16px;
  color: rgba(0,0,0,.25);
}

.transfer-table-member .ant-table-body {
  overflow-y: auto !important;
}