.div-style-table {
  overflow: inherit;
  overflow-x: hidden;
  height: calc(100vh - 775px);
}

.div-style {
height: 200px;
}

.invoice-table .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  /* height: calc(100vh - 372px); */
}

.invoice-table .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  /* height: calc(100vh - 372px); */
}
