.e-schedule.schedule-scheduleComponent {
  max-height: calc(100vh - 278px) !important;
  height: fit-content;
}

.schedule-scheduleComponent.e-schedule .e-timeline-view .e-content-wrap {
  height: 100% !important;
  /* max-height: calc(100vh - 359px) !important; */
  max-height: calc(100vh - 396px) !important;
}

.schedule-scheduleComponent.e-schedule .template-wrap .resource-details .resource-name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.schedule-scheduleComponent.e-schedule .e-timeline-view .e-resource-left-td {
  width: 280px !important;
}

.schedule-scheduleComponent.e-schedule .template-wrap .resource-details {
  padding-left: 10px;
}

.schedule-scheduleComponent.e-schedule.e-device .template-wrap .resource-details .resource-designation {
  display: none;
}

.schedule-scheduleComponent.e-schedule .e-resource-text {
  align-items: center;
  display: block;
  margin-left: unset !important;
  /* white-space: nowrap; 
  width: 50px; 
  overflow: hidden;
  text-overflow: ellipsis; */
}

.schedule-scheduleComponent.e-schedule .e-timeline-view .e-navigate {
  padding-left: 5px !important;
}

.schedule-scheduleComponent colgroup col {
  width: 60px !important;
}

.e-schedule .e-timeline-view .e-resource-cells {
  padding-left: 20px;
}

.e-schedule.schedule-scheduleComponent .e-time-slots {
  padding: 0px 0px 0px 2px !important;
}

.e-schedule .e-timeline-view .e-content-wrap {
  height: 100%;
}

.gantt-view-list-left {
  width: 70%;
  /* margin-bottom: 16px; */
  padding: initial;
}

.template-wrap {
  align-items: center;
  display: flex;
}

.gantt-view-select {
  width: 250px;
  font-size: 13px;
}

.gantt-view-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.gantt-view-datePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 13px;
  width: 165px;
}

.gantt-view-datePicker .ant-input-lg {
  height: unset;
}

.total-items-gantt-view {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.gantt-view-card {
  /* height: 90vh; */
  min-height: 92vmin;
  max-height: 100vmax;
}

.gantt-view-select-team {
  width: auto;
  min-width: 250px;
  margin-left: 10px;
  display: inline-block;
}

.gantt-view-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.gantt-view-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.template-wrap .scheduler-column-avatar .ant-avatar .ant-avatar-circle {
  /* display: none !important; */
}

.scheduler-column-name {
  padding: 5px 10px;
}

.stylesMain-monitor-result {
  background: #f5f5f5;
  height: calc(100vh - 278px);
  border: unset;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stylesMain-monitor-detail {
  display: grid;
}

.stylesMain-monitor-result-icon {
  font-size: 32px !important;
  color: #9a9999 !important;
  margin-bottom: 26px;
}

.stylesMain-monitor-result-title {
  font-size: 14px;
  color: #9a9999;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: rgb(0 0 0 / 87%);
  border-radius: 50%;
  display: inline-block;
  /* margin: 0px 5px; */
  padding: 0px 5px;
}

.dot-tooltip {
  height: 4px;
  width: 4px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
}

.text-dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 170px; */
  display: flex;
  align-items: center;
}

/*------------------------------- Gantt Header schedule-header-bar CSS --------------------------------- */

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
  text-transform: none !important;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-today {
  display: none !important;
}

.schedule-scheduleComponent.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span {
  padding-left: 5px;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #1d3557;
  border: 1px solid #1d3557;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #1d3557;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  border: 1px solid #1d3557;
  color: #1d3557;
}

.schedule-scheduleComponent.e-schedule .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: #bcc5d3;
  font-size: 13px;
  font-weight: 500;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-schedule-timeScale-15m .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

/* .schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-schedule-timeScale-15m .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1D3557 !important;
  font-size: 13px;
  font-weight: 500;
} */

/* .schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-15m, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  background-color: #1D3557 !important;
  color: #1D3557 !important;
} */

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-15m {
  float: left;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-30m {
  float: left;
}

.schedule-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-1h {
  float: left;
}

.schedule-scheduleComponent.e-schedule .e-spinner-pane {
  display: none !important;
}

.scheduler-header {
  display: flex;
  align-items: center;
}

.scheduler-header-img {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.schedule-scheduleComponent.e-schedule .e-timeline-view .e-resource-tree-icon {
  padding: 1px 7px !important;
}
