.icon-upload2 {
  color: #1d3557;
}

.button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-adap {
  width: 125px;
  background-color: #1d3557;
}

.button-save {
  width: 125px;
  min-width: 117px;
  background-color: #1d3557;
  color: #ffffff;
}

.button-save:hover {
  background-color: #ffffff;
  color: #1d3557;
}

.formate-Title {
  margin-left: 6px;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: unset;
}
