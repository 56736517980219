
.loading-spin {
  justify-content: center;
  display: block;
  align-items: center;
  /* width: 100%;
  height: 100%; */
  position: absolute;
  top: 50%;
  left: 50%;
  /* margin-top: -50px; */
  margin: -25px 0 0 -25px;  
}