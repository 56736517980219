.summary-text {
  font-size: 20px;
  color: #000000;
  margin-left: auto;
  padding-top: 4px;
  font-weight: 500;
  width: 190px;

  /* letter-spacing: 1px; */
}
.col2 {
  padding-left: 20px;
}
.week-withdraw {
  padding-left: 3px;
}

.withdraw {
  font-size: 36px;
  color: #000000;
}
.withdraw-text {
  padding-top: 20px;
}

.payd-bar .ant-progress-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-bar .ant-col-18 {
  padding-top: 1px;
}
.week .ant-col-3 {
  width: 9.5%;
}

.text-bar .ant-row {
  padding-top: 5.5px;
}

.statement-table {
  /* width: 95% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px !important; */
  padding-left: 24px;
  padding-right: 24px;
}

/* .statement-table .ant-table {
  border-bottom: 0px solid #e8e8e8;
  -webkit-box-shadow: 0px 1px 2px 0px #00000033;
  box-shadow: 0px 1px 2px 0px #00000033;
  color: #000000; 
} */

.statement-date .ant-input {
  border: unset;
  margin-right: 9px;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}

.statement-date .ant-calendar-picker-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 16px;
}

.content-space {
  margin: '10px 0px 50px 0px';
}

.progress-row {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 10px 0px 10px 0px;
}

.statement-row {
  padding-bottom: 24px;
  color: #000000;
}

.progress-col {
  display: flex;
  align-items: center;
  justify-content: left;
}

.xxxx {
  display: flex;
  align-items: center;
  justify-content: left;
}

.monthPicker-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statement-date .ant-input:focus {
  box-shadow: 0 0 0 0px transparent;
}



