.text-card {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 350;
  float: right;
}

.value-slider-10 {
  padding-left: 163px;
  font-weight: 350;
}
.value-slider-6 {
  padding-left: 179px;

  font-weight: 350;
}
.value-slider-modal-10 {
  padding-left: 350px;
  font-weight: 350;
}
.value-slider-modal-6 {
  padding-left: 357px;
  font-weight: 350;
}

.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
}

.ant-card-small > .ant-card-body {
  padding: 12px;
  box-shadow: 0px 1px 1px 0.01px rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
.icon-edit {
  color: orange;
}
.icon-upload {
  color: orange;
}

.button-card {
  background-color: #1d3557;
  color: #fff;
  font-size: 12px;
  width: 96px;
  height: 32px;
}

.select-multi {
  height: auto;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #ffe8da;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #ffe8da;
}

.ant-select-selection--multiple {
  height: auto;
}

.button-modal .ant-btn {
  font-size: 12px;
  width: 96px;
  height: 32px;
}

.paginatedThumbnails_qxqfp1 {
  height: 90px !important;
}

.image-gallery-index {
  width: 100%;
  top: -60px;
  text-align: center;
  margin: 0px !important;
  background: transparent;
}

.userProfile-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-dashed {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.skill-row {
  justify-content: center;
  align-items: center;
  display: flex;
}

.user-management-team-tree-select .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.user-management-team-tree-select .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.user-management-team-tree-select .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.user-management-role-select .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.user-management-role-select .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.user-management-role-select .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.usersDetail-content-divider {
  margin: 16px 0;
}
