.image-detail {
  width: 100px;
  height: 90px;
  margin: 0 0 0 0px;
  padding-right: 10px;
  padding-bottom: 10px;
  border-radius: 2px;
}

.image-signature {
  width: 150px;
  height: 'auto';
  margin: 0 0 0 0px;
  border-radius: 2px;
}

.data-list-css {
  /* border-bottom: #E5E5E5 solid 2px; */
  padding-top: 10px;
  width: 600px;
  padding-top: 16px;
}

.data-list-photo-css {
  padding-top: 10px;
  padding-bottom: 4px;
  width: 625px;
}

.data-list-photo-css.ant-row {
  padding-top: 4px;
}

.icon-space {
  padding-right: 6px;
  margin: 4px;
}

.header-collapse {
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text-space {
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 4px;
  width: 500px;
}

.text-space-address {
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 14px;
  width: 500px;
  display: flex;
}

.transport-div-tab-scroll {
  width: 100%;
  height: 475px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 25px;
}

.transport-div-tab-scroll::-webkit-scrollbar {
  width: 6px;
}

.transport-div-tab-scroll::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.transport-div-tab-scroll::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.transport-div-tab-scroll::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}

/* #div-tab-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.div-tab-scroll::-webkit-scrollbar{
  width: 12px;
	background-color: #F5F5F5;
}

#div-tab-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
} */

/* .panel-css .ant-collapse .ant-collapse-item {
  border-bottom: #E5E5E5 solid 1px !important;
} */

.panel-css.ant-collapse-item {
  border-bottom: #e5e5e5 solid 1px !important;
  padding: 16px 0px 6px 0px;
}

/* .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #E5E5E5;
} */

.text-space-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-collapse-col {
  padding: 0px 6px;
}

.sub-task-collapse.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 6px;
}

.sub-task-collapse .ant-collapse-header {
  padding-left: unset !important;
}

.subtask-preview-image .ant-modal-mask {
  background-color: rgb(0 0 0 / 5%);
}

.subtask-preview-div-upload {
  display: flex;
  flex-wrap: wrap;
}

.divider-style {
  border-bottom: 1px solid #e5e5e5;
  width: 570px;
  margin-bottom: 10px;
}

.icon-download-subtask {
  color: #1d3557;
  padding-left: 10px;
  margin-top: 2 px;
  margin-right: 8 px;
}

.icon-download-subtask.anticon svg {
  height: 16px;
  width: 16px;
}

/* ***************Css Collapse**************** */
.sub-task-collapse.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}

.sub-task-collapse.ant-collapse {
  border: 0px solid #e5e5e5;
  border-bottom: 0;
  border-radius: 4px;
  background-color: #fff;
}

.sub-task-collapse.ant-collapse > .ant-collapse-item {
  /* border-bottom: 0px solid #E5E5E5; */
  /* border-bottom: unset !important; */
}

.sub-task-collapse .ant-collapse-content {
  border-top: 0px solid #e5e5e5 !important;
}

.sub-task-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fff;
}

.sub-task-collapse.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 6px;
  padding-right: 16%;
}

.sub-task-collapse .ant-collapse-item:last-child > .ant-collapse-content {
  padding-bottom: 2%;
}

.sub-task-collapse.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  padding-bottom: 0.2%;
  padding-left: 0px;
}

.sub-task-collapse.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px;
  padding-right: 0%;
  padding-bottom: 16px;
  padding-left: 0%;
}

.sub-task-collapse.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-top: 0px;
}

.sub-task-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 11px;
}

.button-note-sub-task {
  color: #1d3557;
  cursor: pointer;
  font-size: 13px;
}

.div-subtask-collapse {
  padding: 0px 24px;
}

.container-div-component-content-subtask {
  width: 100%;
  padding-top: 16px;
}
