.task-detail-team-padding {
  padding: 12px;
}

.task-detail-team-text {
  font-size: 14px;
  color: #1d3557;
}

.task-detail-team-select .ant-select-selection--single {
  background-color: #f5f5f5;
}
