.container-list {
  display: flex;
  flex-direction: column;
  padding: 18px;
}
.container-list-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 12px 0px;
}
.container-list-body {
  height: calc(100vh - 265px);
}
.container-list-footer {
  display: flex;
}

.span-address-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
