.input-style {
  width: 100%;
}

.text-action {
  text-align: right;
}

.actknowlege-style-disable {
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  color: #707070;
}

.actknowlege-style {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  color: #1d3557;
  cursor: pointer;
}

.styledAppSelect.ant-select-selection--single .ant-select-selection__rendered {
  border-radius: 0px 5px 5px 0px;
  width: 100%;
}
