.title-label {
  font-weight: bold;
  font-size: 14px;
  color: #1d3557;
  margin-bottom: 6px;
}

.name-p {
  /* font-weight: bold; */
  font-size: 13px;
  color: #1d3557;
  margin-bottom: 2%;
}

.tag-status {
  border-radius: 16px;
}

.information-label-text {
  color: #1d3557;
  font-size: 14px;
  font-weight: bold;
}
