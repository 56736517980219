.place-custom {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #bcc5d3;
}

.place-custom-address {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
}

.row-item-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body-nobin .ant-modal-body {
  max-height: 538px;
  overflow-y: auto;
}

.body-nobin .ant-modal-body::-webkit-scrollbar {
  width: 6px;
}

.body-nobin .ant-modal-body::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.body-nobin .ant-modal-body::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.body-nobin .ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}

.icon-center-location-swap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.modalAdjustForm .ant-form-item-label {
  font-weight: bold;
}

.modalAdjustSelect .ant-select-selection {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.modalAdjustInput {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.modalAdjustSelectLot .ant-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}