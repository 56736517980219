
.member-mapview-input-padding {
  padding: 18px 24px 10px 24px;
}

.member-mapview-list-padding {
  padding: 0px 24px 10px 24px;
  height: calc(100vh - 231px);
  overflow-x: unset;
  overflow-y: auto;
}

.member-mapview-list-padding::-webkit-scrollbar {
  width: 6px;
}

.member-mapview-list-padding::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.member-mapview-list-padding::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.member-mapview-list-padding ::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}


.member-mapview-input {
  /* width: auto; */
}