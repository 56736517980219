.personal-infomation-resetPassword-madal {
  width: 106px;
}

.personal-infomation-resetPassword-madal .ant-modal-title {
  font-size: 14px;
  color: #1d3557;
  font-weight: 600;
}

.personal-infomation-resetPassword-madal .ant-form label {
  font-size: 13px;
}

.personal-infomation-resetPassword-madal .ant-form-item-label > label {
  color: #1d3557;
}

.personal-reset-password-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.personal-reset-password-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.personal-reset-password-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.personal-reset-password-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.personal-reset-password-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.personal-reset-password-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

/* ------------------------------------------------------------- */

.personal-reset-password-modal-confirm-warning .ant-modal-confirm-btns {
  margin-top: 24px;
}

.personal-reset-password-modal-confirm-warning .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.personal-reset-password-modal-confirm-warning .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.personal-reset-password-modal-confirm-warning .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.personal-reset-password-modal-confirm-warning .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}
