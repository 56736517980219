.document-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.document-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.document-modal-confirm .ant-btn-dashed {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.document-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.document-card .ant-card-body {
  padding: 1px 24px 24px 24px !important;
}

.document-card .ant-collapse-borderless {
  background-color: #fff !important;
}

.document-card .ant-collapse-header {
  padding: 12px 16px 10px 0px !important;
}

.document-card .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #e8e8e8 !important;
}
