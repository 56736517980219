.marker-task:focus {
  outline: none;
}

.overlayView-task:focus {
  outline: none;
}

.marker-resource:focus {
  outline: none;
}

.overlayView-resource:focus {
  outline: none;
}

.map-view-list-left {
  width: 70%;
  /* margin-bottom: 16px; */
  padding: initial;
}

.map-view-select {
  width: 250px;
  font-size: 13px;
}

.map-view-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.map-view-rangePicker {
  z-index: 0;
}

.map-view-rangePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 16px;
}

.map-view-rangePicker .ant-input-lg {
  height: unset;
}

.total-items-map-view {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.map-view-card {
  /* height: 90vh; */
  min-height: 92vmin;
  max-height: 100vmax;
}

.icon-task {
  width: 40px;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.div-icon {
  size: 200px;
}

.map-view-select-team {
  width: auto;
  min-width: 250px;
  margin-left: 10px;
  display: inline-block;
}

.map-view-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.map-view-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.stylesMain-map-result {
  background: #f5f5f5;
  /* height: 76vh; */
  height: calc(100vh - 278px);
  border: unset;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stylesMain-map-detail {
  display: grid;
}

.stylesMain-map-result-icon {
  font-size: 32px !important;
  color: #9a9999 !important;
  margin-bottom: 26px;
}

.stylesMain-map-result-title {
  font-size: 14px;
  color: #9a9999;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
}

.map-view-rangePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 13px;
  width: 165px;
}

.map-view-rangePicker .ant-input-lg {
  height: unset;
}

.resource-avatar {
  border: 3px #fff solid;
  z-index: 1;
}

.resource-marker {
  z-index: 1;
}

.marker-task {
  border: unset;
}

/* .gm-style img {
  border: none !important;
  outline-style: none !important;
  border-style: unset;
} */

[tabindex='-1']:focus {
  border: unset;
  outline-style: none;
  outline-color: unset;
  outline: none;
  border-style: none !important;
}

/* .resource-img-avatar {
  border-radius: 50%;
} */

.map-view-popover {
  position: absolute;
  top: 0;
  left: 0;
}

.gm-style .gm-style-iw-c {
  padding: 0px;
}

.gm-style-iw-d {
  overflow-x: unset;
  overflow-y: auto;
}

/* ----------------------------- */

.cluster-marker {
  color: #1d3557;
  background: #ffffff;
  border: 3px solid #1d3557;
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

/* ----------------------------- */

/* ----------------------------- */

.cluster-marker-resource {
  color: #1978c8;
  background: #ffffff;
  border: 3px solid #1978c8;
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------------------------- */
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
