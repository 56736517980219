.assignee-label {
  font-size: 14px;
  color: #1d3557;
  font-weight: 600;
}

/* .assignee-resource-modal .ant-modal-body {
  padding: unset;
} */

.assignee-resource-col-left {
  height: calc(100% - 0px);
}

.assignee-resource-col-right {
  padding: 24px;
  height: calc(100% - 20px);
}

.assignee-resource-divider {
  height: calc(100% - 17px);
  position: absolute;
  margin: unset;
}

.assignee-resource-divider-trackingDetails {
  margin: 10px 0px;
}

.assignee-resource-footer-divider {
  width: calc(100%);
  margin: unset;
}

.assignee-resource-table .ant-table-thead > tr > th {
  padding: 10px 16px;
  font-size: 13px;
  color: #1d3557;
}

.assignee-resource-col-col-left {
  margin-bottom: 12px;
  height: 250px;
}

.assignee-resource-col-col-right {
  margin-bottom: 15px;
  height: 312px;
}

.assignee-resource-footer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 55px;
  padding: 0px 14px;
}

.assignee-resource-col {
  /* padding: 24px; */
  margin-bottom: 19px;
}

.assignee-resource-col-div {
  padding: 24px;
  margin-bottom: 19px;
}

.table-status {
  float: left;
}

.table-status-key {
  display: flex;
  align-items: center;
}

.table-status-icon {
  margin-right: 3px;
  font-size: 18px;
}
.table-status-img {
  width: 18px;
  height: 18px;
}

.assignee-details-title {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}
