.shift-rules-maximum-input {
  width: 100%;
}

.div-form-item {
  display: flex;
}

.from-overtime-col {
  /* padding: 16px !important; */
  margin-right: 16px;
}

.form-item-working-minimum-hours {
  margin: 8px 8px 8px 0px !important;
  width: 396px;
}

.form-item-working-hours {
  margin: 8px 8px 0px 0px !important;
  width: 396px;
}

.form-item-select-working-hours {
  margin: 0px 8px 0px 0px !important;
  width: 324px;
}

.form-item-button {
  margin: 0px 8px !important;
  width: 324px;
}

.row-workHours-from-body {
  margin-bottom: 15px;
}

.col-workHours-from-body {
  margin-top: 39px;
}

.col-overtime-from-body {
  margin-top: 39px;
  /* padding: 16px !important; */
  margin-right: 16px;
}

.row-overtime-from-body {
  display: flex;
  margin: 8px 0px;
}

.switch-swapShift {
  display: flex !important;
}

.switch-swapShift label {
  font-size: 13px;
  margin-right: 10px;
}

.swapShift {
  background-color: #bcc5d3;
}

.swapShift.ant-switch-checked {
  background-color: #1d3557;
}

.swapShift-condition {
  background-color: #9a9999;
}

.swapShift-condition.ant-switch-checked {
  background-color: #1d3557;
}

.div-tag {
  width: 100%;
  height: auto;
  border: 1px solid #e5e5e5;
  border-radius: 1px;
  padding: 0px 5px;
  margin: 0px 0px 12px 0px;
  align-items: center;
}

.div-tag:hover {
  border: 1px solid #fa541c;
  border-radius: 1px;
}

.p-tag-placeholder {
  margin: 0px 8px 0px 0px;
  color: #bfbfbf;
  display: contents;
}

.div-icon-tag {
  float: right;
  position: initial;
}

.icon-tag:hover {
  color: red;
}

.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
  display: contents;
}

/* .shift-holiday-tag {
  background-color: #ffffff;
  color: #1D3557;
  border: 1px solid #1D3557;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
} */

.shift-holiday-tag .anticon-close:hover {
  color: #1d3557;
}

.treeSelect-tags .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
  padding-right: 10px;
  border: 1px solid #e8e8e8;
}

.treeSelect-tags .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.treeSelect-tags .ant-select-selection--multiple .ant-select-selection__rendered {
  align-items: center;
  margin: 0px 5px;
  height: auto;
}

.treeSelect-tags {
  font-size: 13px;
}

.shift-rules-form-item label::after {
  content: none;
}

.maximum-continuous {
  margin: 8px 8px 8px 0px;
}

.maximum-continuous label::after {
  content: none;
}

.switch-swapShift label::after {
  content: none;
}

.form-item-working-minimum-hours label::after {
  content: none;
}

.form-item-working-hours label::after {
  content: none;
}

.form-item-select-working-hours label::after {
  content: none;
}

.form-item-button label::after {
  content: none;
}

.span-color {
  color: transparent;
  margin-top: 32px;
  font-size: 32px;
}

.shift-rules-form-item label {
  font-size: 13px;
}

.shift-rules-select {
  font-size: 13px;
}

.divider-x {
  margin: 15px 0px 0px 0px !important;
}

/*
.shift-rules-select .ant-select-selection--single {
  justify-content: left;
  align-items: center;
  display: flex;
}

.shift-rules-select .ant-select-selection__rendered {
  justify-content: center;
  align-items: center;
  display: flex;
} */

.confirm-modal .ant-modal-content {
  width: 280px;
  margin: auto;
}

.confirm-modal .ant-modal-confirm-body {
  text-align: center;
}

.confirm-modal .ant-modal-body {
  padding: 24px 0px 24px 40px;
  width: fit-content;
}

.confirm-modal .ant-modal-confirm-title {
  font-size: 13px;
}

.confirm-modal .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.confirm-modal .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.confirm-modal .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.confirm-modal .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.confirm-modal .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

.confirm-modal .ant-btn {
  font-size: 13px;
}
