.notice-image {
  vertical-align: middle;
  width: 22px;
  height: 22px;
  border-radius: 50%;
}
/* .kgt-btn-style{
    border-radius: 4px;
    border-color: rgba(254, 99, 2, 0.315) !important;
    color: #1D3557 !important;
    background-color: #ffffff !important;
    width: fit-content;
  } */
.platform-style {
  margin-left: -10px;
  margin-right: 0px !important;
}
.ant-row {
  position: relative;
  height: auto;
  margin-right: 0;
  margin-left: 0;
  padding-top: 0px;
  zoom: 1;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 4px 0 !important;
  font-size: 14px;
}
.notice-image {
  width: 19px;
  height: 19px;
}
.dot-notification {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.badge-custom .ant-badge-count {
  min-width: 20px;
  height: 20px;
}

.big-dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.text-alert {
  font-size: 13;
  font-weight: 600;
  color: black;
  margin-left: -8px;
}

.text-alert .anticon svg {
  color: black;
}

.notifications-card {
  width: 440px;
}

.notifications-card .ant-card-body {
  padding: 0px 0px 24px 24px;
}

.notifications-card .ant-list-empty-text {
  display: none;
}

.see-more {
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.notification-list {
  /* height: 468px; */
  /* overflow: auto; */
  min-height: fit-content;
  max-height: 470px;
  overflow-y: auto;
}

.hover-item {
  border-bottom: 0px solid #e8e8e8 !important;

  cursor: pointer;
  display: block;
  width: 360px;
}
.hover-item .ant-list-split .ant-list-item {
  border-bottom: 0px solid #e8e8e8 !important;
}

.hover-item:hover {
  color: #1d3557;
  background: transparent;
}

.divider-width.ant-divider-horizontal {
  margin: 2px -14px;
}
