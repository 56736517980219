.card-assignee {
  width: 360px;
  height: 400px;
}

.card-assignee-no-task {
  width: 360px;
  height: 70px;
}

.text-assignee {
  height: 30px;
}

.address-assignee-modal {
  margin: 0px 0px 0px 5px;
}

/* p {
    font-size: 12px;
} */

hr {
  height: 1px;
  background-color: #f5f5f5;
  border: none;
}

.content {
  margin-top: 0px;
}

.content-container {
  margin: 15px 0px 15px 0px;
  color: #000000;
  font-size: 13px;
}

.container-detail {
  font-weight: 600;
  color: #000;
  margin: 0px 0px 0px 5px;
  text-align: center;
  margin-right: 15px;
}

.container-detail-p {
  font-weight: 600;
  color: #000;
  margin: 0px 0px 0px 5px;
  text-align: center;
}

.container-detail-status {
  font-weight: 600;
  color: #000;
}

.container-moment {
  margin-right: 10px;
  font-size: 13px;
}

.row {
  display: flex;
  align-items: center;
  height: 50px;
}

.title-p {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.text-p {
  margin-bottom: 0px;
  padding-left: 0px;
}

.avatar-row {
  float: left;
}

.avatar-row-data {
  display: block;
  align-items: center;
  /* text-align: center; */
  margin-bottom: 0px;
  width: 140px;
  /* float: left; */
}

.button-row {
  float: right;
  margin: 0px 0px 0px 0px !important;
}

/* .ant-btn:hover .ant-btn:focus {
  color: #E5E5E5;
} */

.span-assign:hover {
  color: #e5e5e5;
}

.text-task {
  height: 40px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  color: #000000;
  font-weight: 500;
}

/* .ant-popover-content {
 
} */

.text-name {
  font-size: 14px;
  margin-bottom: 5px;
  text-align: center;
  font-weight: bold;
}

.title-h2 {
  /* display: flex;
  align-items: center;
  margin: 0px 0px 0px 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.title-h3 {
  margin: 15px 0px 15px 0px;
}

.text-phone {
  background: #1d3557;
  color: #ffffff;
  border-radius: 9px;
  border: none;
  font-size: 10px;
  padding: 0px 14px 0px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.text-phone-modal {
  background: #1d3557;
  color: #ffffff;
  border-radius: 16px;
  border: none;
  /* height: 24px; */
  margin: 0px;
  font-size: 14px;
  padding: 0px 14px 0px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-row-data-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-assignee {
  display: block;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 0px;
}

.assignee .ant-card-body {
  padding: 0px 0px 0px 0px !important;
  line-height: 2;
}

.assignee .content-container {
  padding-left: 20px;
  padding-right: 20px;
}

.assignee .ant-card-head {
  padding: 0px 15px;
}

.detail-text {
  font-size: 12px;
  color: #000000;
  text-align: left;
  padding-left: 20px;
  font-size: 13px;
  float: left;
}

.ant-card-actions {
  padding-right: 10px;
}

/* style ส่วนของ modal */
/* .modalAssignee .ant-modal-content {

} */

.modalAssignee .ant-modal-body {
  padding: 10px;
}

.col-detil {
  padding: 5px 10px 5px 20px;
}

.content-avatar {
  /* margin-bottom: 10px; */
  /* display: inline-flex;
  align-items: center; */
  display: flex;
  align-items: center;
  justify-content: right;
}

.avatar {
  float: left;
}

.col-button {
  height: 56px;
  float: right;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.detail-timeline {
  margin-left: 20px;
}

.div-details {
  margin: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.div-details-tag-status {
  color: #fff;
  border: none;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: right;
  border-radius: 16px;
  margin: 0px 0px 0px 5px;
  padding: 0px 14px 0px 14px;
}

.div-details-tag-p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  margin: 0px;
  text-align: center;
}

.styte-details {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 30px;
}

.styte-details-address {
  border-top: black solid 1px;
  margin-top: 10px;
  padding-top: 5px;
  display: flex;
  /* align-items: center; */
  justify-content: right;
  height: 30px;
}

.ant-list-bordered .ant-list-header {
  padding-left: 10px;
  padding-right: 10px;
}

.card-resource {
  padding: 10px 10px 10px 10px;
}

.title-modal .ant-badge-status-text {
  font-size: 16px;
  font-weight: bold;
}

/* .ant-table-selection-column {
  display: none;
} */

.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fff2ec;
}

.img-col-modal {
  width: 50px;
}

.text-col-modal-head {
  font-size: 14px;
  margin-bottom: 1px;
  padding-bottom: 1px;
}

.text-col-modal-h3 {
  font-size: 12px;
  margin-bottom: 1px;
  padding-bottom: 1px;
}

.popover-size {
  width: 370px;
  height: auto;
}
.col-count-tasks {
  justify-content: center;
  align-items: center;
  /* display: flex; */
  cursor: pointer;
  text-align: right;
}

.resource-list {
  cursor: pointer;
  padding: unset;
}

.resource-popover-divider.ant-divider-horizontal {
  margin: 0px;
}
