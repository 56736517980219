.shift-div-month-picker {
  padding: 8px 24px 16px 24px;
}

.shift-div-button-planning {
  padding: 8px 24px 16px 24px;
  float: right;
}

.shift-month-picker .ant-calendar-picker-input {
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #d9d9d9;
  cursor: pointer; 
}