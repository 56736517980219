.tab-filter-load-route.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  background-color: #fff;
}

.tab-filter-load-route .ant-collapse-item:last-child>.ant-collapse-content {
  background-color: #f5f5f5;

}

.tab-filter-load-route .ant-collapse-item:last-child>.ant-collapse-header {
  padding: 10px !important;
}

.tab-filter-load-route .collapse-menu.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  padding: 10px !important;
}

.div-load-and-route-list {
  cursor: pointer;
  padding: 16px;
}

.active-select {
  background-color: #e0e7f5;
}

.active-select-text {
  color: #1d3557;
}

.lisence-load-and-route-div {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
}

.list-vehicles {
  overflow-y: auto;
}

.header-filter-vehicle {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0px 4px ;
}

.dot-filter-vehicle {
  top: 4px;
}

.text-header-vehicle {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.15; */
  letter-spacing: normal;
  text-align: left;
  color: #050505;
}

.text-span-vehicle {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.33; */
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.tab-filter-load-route.ant-collapse {
  border-top: 1px solid #e5e5e5;
  border-bottom: unset;
  border-right: unset;
  border-left: unset;
}

.filter-load-div {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.filter-load-number.ant-input-number {
  min-width: 130px;
  max-width: 140px;
}

.filter-load-select.ant-select {
  width: 100%;
}

.filter-space-div {
  margin: 0px 0px 10px 0px;
}

.filter-lbl-load {
  font-size: 13px;
  margin-bottom: 4px;
}

.header-filter-collapse {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
}

.vehicleSvg {
  height: 25px;
  width: 25px;
  filter: invert(53%) sepia(0%) saturate(1590%) hue-rotate(197deg) brightness(81%) contrast(89%);
}

.vehicleSvg-active {
  height: 25px;
  width: 25px;
  filter: invert(67%) sepia(13%) saturate(5155%) hue-rotate(188deg) brightness(86%) contrast(87%);
}

.icon-vehicle-list.anticon {
  padding-top: 14px;
}

.div-list-text-show {
  height: 25px;
}

.text-group {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
}

.text-content {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  text-align: left;
  color: #1d3557;
}