.request-type-card {
  min-height: calc(100vh - 112px);
}

.request-type-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.request-type-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.request-type-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.request-type-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.request-type-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.request-type-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

.request-type-total-items {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.request-table .ant-table-body {
  overflow-y: auto !important;
}

.request-table .ant-pagination-total-text {
  margin-right: -16px !important;
}
