.dot-badge {
  display: grid;
}

.dot-badge .ant-badge-status-dot {
  width: 12px;
  height: 12px;
}

.div-name-comment {
  padding-bottom: 8px;
}

.tracking-row {
  padding: 0px 0px 5px 0px;
}

.tracking-title-label {
  font-weight: bold;
  font-size: 13px;
  color: #1d3557;
}

.title-col-label {
  height: 55px;
  align-items: center;
  display: inline-flex;
}

.tracking-timeline {
  margin: 0px 5px;
}
