@media print {

    /* @page {
        size: 150mm 100mm !important;
        orientation: landscape !important;
    }

    body {
        margin: 0;
    }

    .printable {
        page-break-before: always;

    } */

}