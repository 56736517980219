.assignee-detail {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  cursor: pointer;
}

.assignee-detail-disable {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  opacity: 0.4;
  /* cursor: pointer; */
}

.assignee-detail-avatar {
  margin-right: 16px;
}

.title-col-label {
  /* padding: 8px 0px 0px 8px !important; */
  height: 55px;
  align-items: center;
  display: inline-flex;
}

.icon-status {
  margin-right: 5px;
  font-size: 18px;
}

.assignee-title-label {
  font-weight: bold;
  font-size: 14px;
  color: #1d3557;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  fill: black;
}
.table-status-img {
  width: 18px;
  height: 18px;
}

.Statusdot .ant-badge-status-dot {
  width: 14px;
  height: 14px;
}

.assignee-beetwen-Badge-dot .ant-badge-status-dot {
  margin: 0px 4px;
  width: 4px;
  height: 4px;
}

.assignee-beetwen-Badge-dot .ant-badge-status-text {
  margin-left: unset;
}

.Statusdot {
  /* margin-right: 5px; */
  margin: 0px 8px 0px 0;
}

.Statusdot .ant-badge-status-text {
  margin: 0%;
}

.assignee-details-title {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}
