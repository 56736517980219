.sroll-checkbox-group {
  max-height: 160px;
  overflow-y: auto;
}

.sroll-checkbox-group-status {
  height: auto;
  overflow-y: auto;
}

.custom-row-select-all.ant-row {
  padding: 8px 0px;
}

.container-group-select-all {
  padding: 5px 24px;
}

.scroll-sm-none::-webkit-scrollbar {
  width: 6px;
  /* display: none; */
}

.scroll-sm-none::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.scroll-sm-none::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.scroll-sm-none::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}

.style-mg-0 {
  margin: 0 10px;
  width: 92%;
  min-width: 92%;
}

.select-group-style-asset .ant-checkbox-group-item {
  margin-right: 8px;
  padding: 14px 25px 5px 30px;
}

.select-group-style-status .ant-checkbox-group-item {
  margin-right: 20px;
  padding: 6px 25px 5px 0px;
  display: flex;
  align-items: center;
}

.select-group-style-asset.select-group-style {
  display: flex;
  flex-direction: column;
}

.asset-datepicker-item {
  padding: 0px 0px 10px 0px;
}

.header-title-advance-filter {
  font-size: 14px;
  font-weight: normal;
  color: #1d3557;
}
