@font-face {
  font-family: 'Open Sans';
  src: url('../../assets/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../../assets/fonts/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../../assets/fonts/open-sans-v17-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

.book {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  /* overflow: auto; */
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 210mm;
  max-height: 360mm;
  padding: 10mm;
  margin: 5mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: 'Open Sans';
}
.subpage {
  /* padding: 1cm; */
  /* border: 1px rgb(187, 183, 183) solid; */
  height: 277mm;
  /* outline: 2cm #FFEAEA solid; */
}

.subpage-header {
  position: relative;
  padding-bottom: 10px;
  border-bottom: #fe9f64 solid 2px;
}

.subpage-header .subpage-header-company-name {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.subpage-header h1,
p {
  margin-bottom: 0.1em;
}

.subpage-header p {
  font-size: 12px;
}

.subpage-header .header-logo {
  position: absolute;
  right: 0px;
  top: 10px;
  font-size: 36px;
  color: #1d3557;
}

.subpage-customer {
  position: relative;
  padding-bottom: 10px;
  /* border-bottom: 1px rgb(187, 183, 183) solid; */
}

.subpage-customer .subpage-customer-right {
  position: absolute;
  right: 0px;
  top: 0px;
  text-align: right;
}

.subpage-customer .subpage-customer-left .subpage-customer-name {
  padding: 15px 0 15px 0;
}

.subpage-customer .subpage-customer-left .subpage-customer-address-name {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  padding-bottom: 15px;
}

.subpage-customer .subpage-customer-left .subpage-customer-address {
  width: 45%;
}

.subpage-customer .subpage-customer-right .subpage-inovice-header {
  padding: 15px 0 15px 0;
}

.subpage-customer .subpage-customer-right .subpage-inovice-no {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  padding-bottom: 15px;
}

.subpage-customer .subpage-customer-right .subpage-date-of-issue-header {
  padding: 0 0 10px 0;
}

.subpage-customer .subpage-customer-right .subpage-invoice-date {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans';
  padding-bottom: 15px;
}

.m-l-30 {
  margin-left: 30px;
}

.subpage-item {
  padding: 15px 0 15px 0;
}

.invoice-table-item {
  width: 100%;
  font-size: 12px;
}

.invoice-table-item td {
  border-bottom: #e5e5e5 solid 1px;
}

.invoice-table-item th {
  text-align: center;
  font-weight: unset;
  background-color: #f5f5f5;
  padding: 8px 0 8px 0;
}

.invoice-table-item td {
  padding: 8px 0 8px 0;
  text-align: center;
}

.invoice-table-item tr th:nth-child(1) {
  width: 6%;
}

.invoice-table-item tr th:nth-child(2) {
  width: 17%;
}

.invoice-table-item tr th:nth-child(3) {
  width: 17%;
}

.invoice-table-item tr th:nth-child(4) {
  width: 20%;
}

.invoice-table-item tr th:nth-child(5) {
  width: 20%;
}

.invoice-table-item tr th:nth-child(6) {
  width: 20%;
}

.invoice-table-item tr td:nth-child(1) {
  text-align: center;
}

.invoice-table-item tr td:nth-child(2) {
  text-align: center;
}

.invoice-table-item tr td:nth-child(3) {
  text-align: center;
}

.invoice-table-item tr td:nth-child(4) {
  text-align: center;
  /* padding-right: 35px; */
}

.invoice-table-item tr td:nth-child(5) {
  text-align: center;
  /* padding-right: 35px; */
}

.invoice-table-item tr td:nth-child(6) {
  text-align: center;
  /* padding-right: 35px; */
}

.subpage-footer {
  margin-top: 25px;
  position: relative;
}

.subpage-footer p {
  padding-bottom: 5px;
}

.subpage-footer .subpage-footer-left {
  width: 50%;
}

.subpage-footer .subpage-footer-left .subpage-footer-left-remark {
  width: 100%;
  min-height: 105px;
  padding: 15px;
  border: #e5e5e5 solid 1px;
  border-radius: 5px;
}

.subpage-footer .subpage-footer-right {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.subpage-footer .subpage-footer-right .subpage-footer-right-sub {
  position: relative;
}

.subpage-footer .subpage-footer-right .subpage-footer-right-sub .subpage-footer-right-sub-left {
  width: 50%;
  text-align: right;
}

.subpage-footer .subpage-footer-right .subpage-footer-right-sub .subpage-footer-right-sub-right {
  width: 50%;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
    -webkit-print-color-adjust: exact;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    overflow: hidden;
  }

  th {
    background-color: #f5f5f5;
  }
}
