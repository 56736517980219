.post-card {
  margin-bottom: 16px;
}

.post-card .ant-card-body {
  padding: 24px 0 24px 0;
}

.post-card-head {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
}

.post-card-head-left {
  display: flex;
  align-items: center;
}

.post-card-head-left-avatar {
  margin-right: 30px;
}

.post-card-head-left-text {
  display: flex;
  align-items: center;
}

.post-card-left-text {
  line-height: 1.75;
}

.post-card-left-text-head {
  font-size: 13px;
  font-weight: 600;
  color: #1d3557;
}

.post-card-left-text-dot {
  margin: 0px 5px;
  top: -5px;
  position: relative;
}

.modal-post-card-left-text {
  line-height: 1.75;
}

.modal-post-card-left-text-head {
  font-size: 12px;
  color: #1d3557;
}

.modal-post-card-left-text-dot {
  margin: 0px 5px;
  top: -5px;
  position: relative;
}

.modal-post-card-head {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
}

.modal-post-card-head-left {
  display: flex;
  align-items: center;
}

.modal-post-card-head-left-avatar {
  margin-right: 16px;
}

.modal-post-card-head-left-text {
  display: flex;
  align-items: center;
}

.modal-post-card-text-12 {
  font-size: 12px;
  color: #1d3557;
}

.modal-post-message {
  display: flex;
  font-size: 12px;
  color: #1d3557;
}

.modal-post-message-text {
  cursor: pointer;
  color: #1d3557;
  font-size: 12px;
}

.post-message {
  display: flex;
  font-size: 13px;
  color: #1d3557;
  white-space: pre-wrap;
}

.post-message-text {
  cursor: pointer;
  color: #1d3557;
  font-size: 13px;
}

.post-card-create-post {
  padding: 0px 24px;
  margin: 20px 0 20px 0;
  line-height: 2.36;
}

.post-card-text-11 {
  font-size: 12px;
  color: #707070;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.post-card-text-12 {
  font-size: 12px;
  color: #707070;
}

.post-card-text-13 {
  font-size: 13px;
  color: #1d3557;
}

.post-card-image-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
}

.post-card-image-column-1 {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  padding: 0 1px;
  height: 415px;
}

.post-card-image-column-2 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px;
  height: 215px;
}

.post-tags {
  border-radius: 2px;
  border: solid 1px #1d3557;
  color: #1d3557;
  height: 22px !important;
  padding: 0px 8px;
  cursor: pointer;
}

.post-address {
  display: flex;
  justify-items: center;
}

.post-card-close {
  margin-left: 10px;
}

.post-card-icon-close {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.25);
}

.thumbnail-button {
  border: unset;
}

.modal-post-view .ant-modal-body {
  background-color: rgb(242, 243, 245);
  height: 550px;
  overflow-y: auto;
}

.modal-post-view::-webkit-scrollbar {
  width: 6px;
}

.modal-post-view::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.modal-post-view::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.modal-post-view::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}
