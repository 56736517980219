.shift-type-modal .ant-modal-title {
  font-size: 14px;
  font-weight: bold;
  color: #1d3557;
}

.shift-type-modal label {
  font-size: 13px;
  font-weight: bold;
}

.workDay-checkbox-shift-type .ant-checkbox + span {
  padding-right: 20px;
}

.team-shift-divider {
  margin-bottom: 20px !important;
}

.div-skill-from-body {
  display: flex;
}

.skills-form-item {
  margin-right: 16px !important;
}

.skill-levels-form-item {
  margin-right: 16px;
  display: block;
}

.min-require-form-item {
  margin-right: 16px;
}

.shift-type .ant-table {
  -webkit-box-shadow: 0px 1px 2px 0px #00000033;
}

.action-button-popover {
  display: grid;
}

.work-day-from {
  display: flex;
  align-items: flex-end;
}

.shift-type-form-item {
  margin: 8px 8px 0px 8px !important;
}

.shift-type-form-item-select {
  margin: 8px 8px 0px 0px !important;
}

.shift-type-shift-work {
  margin-right: 16px !important;
  width: auto;
}

.shift-type-shift-continue {
  justify-content: space-between !important;
  display: grid !important;
}

.form-item-input-addon {
  width: 260px !important;
}

.form-item-input-addon .ant-input-group-addon {
  width: 65px;
  font-size: 13px;
}

.continue-shift-restrict .ant-select-selection--multiple {
  width: 438px !important;
  height: 32px;
  padding-bottom: 0px;
}

.continue-shift-restrict .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
}

.continue-shift-restrict .ant-select-selection__choice__remove {
  color: #1d3557;
}

.continue-shift-restrict .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.form-item-role .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
}

.form-item-role .ant-select-selection__choice__remove {
  color: #1d3557;
}

.form-item-role .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.shift-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.shift-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.shift-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.shift-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.shift-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.shift-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

.popover-action {
  border: unset;
  font-size: 13px;
  width: 96px;
}

.skills-span {
  color: transparent;
}
