.ant-switch-checked {
  background-color: #1d3557;
}

.title-name {
  margin-left: 33px;
  display: flex;
  align-items: center;
}

.title-span-name {
  color: #1d3557;
  font-size: 16px;
  cursor: pointer;
}

.title-span-name-no-pointer {
  color: #1d3557;
  font-size: 16px;
}

.list-item-personal {
  display: flex;
  padding: 0px 0px 0px 25px !important;
}

.hr {
  margin-left: 33px;
}

.card-personal .ant-card-body {
  padding: 24px;
}

.image-container {
  position: relative;
  width: 150px;
  height: 150px;
  align-items: center;
  margin: auto;
}
.image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  color: #fff;
  border-radius: 50%;
  border: #fff;
}
.image-container:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.userProfile-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-dashed {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userProfile-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.select-hidden-gender .ant-select-selection {
  border: 1px solid #fff;
}

.select-hidden-gender .ant-select-arrow svg {
  display: none;
}

.select-hidden-gender .ant-select-selection__rendered {
  margin-left: 5px;
}

.select-hidden-position .ant-select-selection {
  border: 1px solid #fff;
}

.select-hidden-position .ant-select-arrow svg {
  display: none;
}

.select-hidden-position .ant-select-selection__rendered {
  margin-left: 5px;
}

.div-input-fullname {
  display: flex;
  align-items: center;
}

.date-work .ant-calendar-input-wrap {
  display: none;
}

.date-work .ant-calendar-picker-input.ant-input {
  border: none;
  padding-left: 5px;
  padding-bottom: 1px;
  cursor: pointer;
  padding-right: unset;
}

.birthday-work .ant-calendar-picker-input.ant-input {
  border: none;
  padding-left: 5px;
  padding-bottom: 1px;
  cursor: pointer;
}

.p-style-q {
  cursor: pointer;
  margin: 5px;
}

.p-style-no-pointer {
  margin: 5px;
}

.p-style-w {
  width: 100%;
}

.personal-reset-password {
  float: right;
  cursor: pointer;
}

.personal-reset-password-span {
  color: #1d3557;
}
