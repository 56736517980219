.p-label {
  font-size: 14px;
  color: #1d3557;
  font-weight: 600;
}
.p-rapidwork-label {
  font-size: 14px;
  color: #e73845;
  font-weight: 600;
  margin-left: 3px;
}
.inline-tx {
  display: inline-flex;
}

.p-text {
  color: #1d3557;
}

.dashboard-card {
  min-height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;
}
