.engagement-action-card .ant-card-body {
  padding: unset;
}

.engagement-action-reportV7 {
  padding: 12px 16px;
}

.engagement-action-filter {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
}

.engagement-checkableTag {
  cursor: pointer;
  padding: 6px 26px;
  margin: unset;
}

.engagement-checkableTag.ant-tag {
  font-size: 13px;
}

.engagement-checkableTag.ant-tag-checkable-checked {
  background-color: #e0e7f5;
  color: #1d3557;
  font-weight: 600;
}

.engagement-action-filter-status {
  display: flex;
}

.engagement-action-filter-sorting {
  display: flex;
}

.engagement-status-filter-text {
  align-content: center;
  display: inline-grid;
}

.engagement-status-filter-text-span {
  font-size: 13px;
  color: #9a9999;
  margin-right: 24px;
}

.engagement-action-divider {
  margin: unset;
}

.engagement-action-divider.ant-divider-vertical {
  height: 2.063em !important;
}
