.ant-pagination-options {
    float: left;
    margin: unset;
    margin-left: 15px;
}

.ant-pagination.mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px;
    float: left;
}