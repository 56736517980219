.collapse-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.content {
  padding: 6px 20px 16px;
}

.collapse-label{
  color: #1D3557;
  font-size: 16px;
  font-weight: bold;
}

.collapse-parent{
  height: 0px;
  overflow: hidden;
  transition: height ease 0.5s;
  margin-top: 6px;
}

.collapse-parent.show{
  height: auto;
}
