.assignee-detail {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.assignee-detail-avatar {
  margin-right: 16px;
}

.title-col-label {
  /* padding: 8px 0px 0px 8px !important; */
  height: 55px;
  align-items: center;
  display: inline-flex;
}

.icon-status {
  margin-right: 5px;
  font-size: 18px;
}

.assignee-title-label {
  font-weight: bold;
  font-size: 14px;
  color: #1d3557;
}

.assignee-tracking-timeline {
  margin: 0px 5px;
}
