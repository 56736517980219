.modal-width {
  width: 800px;
}

.style-switch {
  display: flex;
  align-items: center;
}

.date-width {
  width: 100%;
}

.addOnBefore .ant-input-group-addon:first-child {
  width: 120px;
  text-align: left;
}

.margin-item-holidayname {
  margin-bottom: 5px;
}

.holidays-form-item label::after {
  content: none;
}

.holidays-form-item label {
  font-size: 13px;
}

.body-padding .ant-modal-body {
  padding: 10px 24px;
}

.modal-style-l {
  padding: 15px 24px;
}

.modal-style-title .ant-modal-confirm-title .ant-modal-confirm-body {
  font-size: 10 !important;
}

.font-size-modal {
  font-size: 13;
}

.holiday-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.holiday-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.holiday-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.holiday-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.holiday-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.holiday-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}
