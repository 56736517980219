.asset-datepicker-label {
  font-size: 12px;
  color: #707070;
  /* padding: 10px 0px 0px 0px; */
}

.asset-datepicker-item {
  padding: 0px 0px 10px 0px;
}

.asset-datepicker {
  width: 100%;
}

.asset-search-max-min-item {
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
