.org-card-menu {
  min-height: calc(100vh - 112px);
}

.org-card-menu .ant-card-body {
  padding: unset;
}

.org-card-menu-body {
  padding: 0px 0px 24px 0px;
}

.org-card-search-input {
  padding: 24px 24px 0px 24px;
  margin-bottom: 8px;
}

.org-card-menu-tree {
  height: calc(100vh - 254px);
  overflow: auto !important;
  margin-left: 24px;
}

/* ปรับขนาดของ Scroll bar ให้เป็นขนาดเล็ก */

.org-card-menu-tree::-webkit-scrollbar {
  width: 6px;
}

.org-card-menu-tree::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.org-card-menu-tree::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.org-card-menu-tree::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}