.menu-columns .menu-columns-list-item {
  max-height: 400px;
  overflow: auto;
}

.list-view-table .ant-pagination-total-text {
  margin-right: -16px;
}

.scroller {
  width: 2500px;
  height: 185px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.stylesMain-list-view-body {
  padding: 24px;
}

.list-view-header-col-content {
  display: grid;
  align-items: center;
  justify-content: center;
}

.list-view-header-col-p {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  color: #1d3557;
}

.list-view-header-col-text {
  font-size: 24px;
  text-align: center;
  color: #1d3557;
}

.list-view-list-left {
  width: 70%;
  padding: initial;
}

.list-view-select {
  width: 250px;
  font-size: 13px;
}

.list-view-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.list-view-select-team {
  width: auto;
  min-width: 250px;
  margin-left: 10px;
  display: inline-block;
}

.list-view-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.list-view-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.list-view-rangePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 13px;
  width: 290px;
  height: 32px;
  margin-right: 15px;
}

.list-view-rangePicker .ant-calendar-range-picker-separator {
  padding-top: 8px;
}

.list-view-rangePicker .ant-input-lg {
  height: unset;
}

.total-items-list-view {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.list-view-a-task-view {
  color: #1d3557;
}
