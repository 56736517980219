.title-holiday .ant-card-head-title {
  padding-left: 22px;
  font-size: 13px;
}
.border-card-top {
  border-top-style: none;
}

.letter-space .ant-card-body {
  padding-left: 45px;
  padding-top: 20px;
}

.add-button-right {
  float: right;
}

.button-font-size {
  font-size: 13px;
}

.onTop {
  height: 100%;
  vertical-align: top;
}

.holiday-card {
  height: 100vh;
}

.company-information-detail-address-text {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}

.services-modal-confirm .ant-btn-primary {
  border-radius: 8px !important;
  color: #fff !important;
  background-color: #6490cf !important;
  border-color: #6490cf !important;
}

.services-modal-confirm .ant-btn-primary:hover {
  border-radius: 8px !important;
  color: #6490cf !important;
  background-color: #fff !important;
  border-color: #6490cf !important;
}

.services-modal-confirm .ant-btn-danger {
  border-radius: 8px !important;
  color: #6490cf !important;
  background-color: #ffff !important;
  border-color: #6490cf !important;
}

.services-modal-confirm .ant-btn-danger:hover {
  border-radius: 8px !important;
  color: #ffff !important;
  background-color: #6490cf !important;
  border-color: #6490cf !important;
}
